import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import NewsletterForm from './NewsletterForm';
import NewsletterReview from './NewsletterReview';

const NewsletterNew = () => {
    const [showReview, toggleShowReview] = useState(false);

    return(
        <div>
            <h2>Nytt nyhetsbrev</h2>
            {
                showReview ? (
                    <NewsletterReview toggleShowReview={toggleShowReview} />
                ) : (
                    <NewsletterForm toggleShowReview={toggleShowReview} />
                )
            }
        </div>
    );
}

export default reduxForm({
    form: "newsletterForm"
})(NewsletterNew);