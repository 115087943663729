import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
    return(
        <Route
            {...rest}
            render = { props => (
                authed
                    ? <Component {...props} />
                    : <Redirect to="/login" />
            )}
        />
    );
}

function mapStateToProps({ auth }) {
    return {
        authed: auth
    };
}

export default connect(mapStateToProps, actions)(PrivateRoute);