import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import formFields from './fields';

const NewsletterReview = ({ toggleShowReview, formValues, submitNewsletter, history }) => {
    return ( 
        <>
            <h5>Se over og send</h5>
            {
                formFields.map(({ name, label }) => {
                    return(
                        <div key={name}>
                            <label>{label}</label>
                            <div>
                                {formValues[name]}
                            </div>
                        </div>
                    );
                })
            }
            <button
                className="btn yellow darken-3 marginTop"
                onClick={() => toggleShowReview(false)}
            >
                <FontAwesomeIcon icon={faCaretLeft} className="iconMarginRight" />
                Tilbake
            </button>
            <button
                className="btn aresBlue right marginTop"
                onClick={() => submitNewsletter(formValues, history)}
            >
                Send
                <FontAwesomeIcon icon={faPaperPlane} className="iconMarginLeft" />
            </button>
        </>
     );
}

function mapStateToProps(state) {
    return {
        formValues: state.form.newsletterForm.values
    };
}
 
export default connect(mapStateToProps, actions)(withRouter(NewsletterReview));