import { FETCH_NEWSLETTERS } from "../actions/types";

const newsletterReducer = (state = [], action) => {
    switch(action.type) {
        case FETCH_NEWSLETTERS:
            return action.payload;
        default:
            return state;
    }
};

export default newsletterReducer;