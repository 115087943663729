import axios from 'axios';
import { FETCH_USER, FETCH_NEWSLETTERS, FETCH_PAGES, FETCH_PAGE } from './types';

export const fetchUser = () => async dispatch => {
    const res = await axios.get("/api/current_user");
    dispatch({ type: FETCH_USER, payload: res.data });
};

export const login = (values, history) => async dispatch => {
    const res = await axios.post("/api/login", values);
    if(res) {
        history.push("/dashboard");
    }
    dispatch({ type: FETCH_USER, payload: res.data });
};

export const registerUser = (values) => async dispatch => {
    const res = await axios.post("/api/register", values);
    console.log(res);
};

export const submitNewsletter = (values, history) => async dispatch => {
    const res = await axios.post("/api/newsletters", values);

    history.push("/newsletters");

    if(res) {
        console.log("Newsletter submission successfull");
    } else {
        console.log("Newsletter submission failed:\n"+res);
    }
};

export const fetchNewsletters = () => async dispatch => {
    const res = await axios.get("/api/newsletters");
    dispatch({ type: FETCH_NEWSLETTERS, payload: res.data });
};

export const fetchPages = () => async dispatch => {
    const res = await axios.get("/api/pages");
    dispatch({ type: FETCH_PAGES, payload: res.data });
};

export const fetchPage = (pageID) => async dispatch => {
    const res = await axios.get(`/api/page/${pageID}`);
    dispatch({ type: FETCH_PAGE, payload: res.data });
};

export const submitPage = (values, history) => async dispatch => {
    const res = await axios.post("/api/pages", values);

    console.log(values);

    if(res) {
        console.log("Page creation successfull");
        history.push("/dashboard");
    } else {
        console.log("Page creation failed:\n"+res);
    }
}

export const deletePage = (pageID) => async dispatch => {
    const res = await axios.delete(`/api/pages/${pageID}`);

    if(res) {
        console.log("Page deletion successfull");
    } else {
        console.log("Page deletion failed:\n"+res);
    }
}

export const updatePage = (values, history) => async dispatch => {
    const res = await axios.post(`/api/pages/${values.pageID}`, values);

    if(res) {
        console.log("Page updated successfully");
        history.push(values.path);
    } else {
        console.log("Page update failed:\n"+res);
    }
}