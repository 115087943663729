import { FETCH_PAGES } from "../actions/types";

const pageReducer = (state = [], action) => {
    switch(action.type) {
        case FETCH_PAGES:
            return action.payload;
        default:
            return state;
    }
};

export default pageReducer;