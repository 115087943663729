import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const Page = ({ pageID, title, body, lastUpdated, authed, deletePage, fetchPages }) => {
    return ( 
        <>
            <div className="pageGrid">
                <div className="pageTitle">
                    <h2>{title}</h2>
                </div>
                <div className="pageTimestamp">
                    <p>Sist oppdatert {new Date(lastUpdated).toLocaleDateString()}</p>
                </div>
            </div>
            {
                authed && (
                    <div style={{ width: "100%", paddingBottom: "1rem", textAlign: "right" }}>
                        <Link
                            to={{ pathname: "/pages/edit", pageID }}
                            className="btn aresBlue"
                        >
                            <FontAwesomeIcon icon={faPen} className="iconMarginRight" />
                            Rediger side
                        </Link>
                        <Link
                            to="/dashboard"
                            className="btn red btnMarginLeft"
                            onClick={() => { deletePage(pageID); fetchPages() }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="iconMarginRight" />
                            Slett side
                        </Link>
                    </div>
                )
            }
            <div className="reverseMaterializeListStyling">{parse(body)}</div>
        </>
    );
}
 
export default connect(null, actions)(Page);