import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import TextEditor from './TextEditor';
import TitleInputField from './TitleInputField';
import Banner from './Banner';

const PageEditor = ({ page, fetchPage, updatePage, fetchPages, history, ...props }) => {

    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [path, setPath] = useState("");
    const pageID = props.location.pageID;
    
    useEffect(() => {
        fetchPage(pageID);
    },[fetchPage, pageID]);

    useEffect(() => {
        if(page) {
            setTitle(page[0].title);
            setBody(page[0].body);
            setPath(page[0].path);
        }
    },[page]);

    const savePage = () => {
        if(page && title && body && path) {
            const values = { pageID, title, body, path };
            updatePage(values, history);
        } else {
            console.log("ERROR IN ONE OR MORE VALUE\nPage: "+page+"\nTitle: "+title+"\nBody: "+body+"\nPath: "+path);
        }
    }

    return(
        title !== "" && body !== "" ? (
            <div>
                <Banner type="warnBanner" text="Du er nå i redigeringsmodus. Husk å lagre endringer du ønsker å beholde." />
                <div className="editorTopBar">
                    <TitleInputField title={title} setTitle={setTitle} />
                    <button
                        className="btn green editorSaveBtn"
                        onClick={() => { savePage(); fetchPages(); }}
                    >
                        Lagre & publiser
                    </button>
                </div>
                <TextEditor content={body} setBody={setBody} />
            </div>
        ) : (
            <p>Laster inn...</p>
        )
    )
}

function mapStateToProps({ page }) {
    return {
        page
    };
}

export default connect(mapStateToProps, actions)(PageEditor);