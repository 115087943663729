import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

const Banner = ({ type, text }) => {
    return (
        <div className={'banner '+type}>
            {
                type === "warnBanner" && <FontAwesomeIcon className="bannerIcon" icon={faExclamation} />
            }
            {
                type === "errBanner" && <FontAwesomeIcon className="bannerIcon" icon={faTimes} />
            }
            {
                type === "succBanner" && <FontAwesomeIcon className="bannerIcon" icon={faCheck} />
            }
            {
                type === "infoBanner" && <FontAwesomeIcon className="bannerIcon" icon={faExclamation} />
            }
            {text}
        </div>
    );
}

export default Banner;