import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ADMIN_PAGES from './pages';

const AdminNav = ({ pages }) => {
    return ( 
        <>
        {
            ADMIN_PAGES.map(({ path, title }) => {
                return(
                    <Link to={path} className="navLink adminNavLink" key={path}>{title}</Link>
                )
            })
        }
        <br /><br />
        {
            pages.map(({ path, title }) => {
                return(
                    <Link to={path} className="navLink adminNavLink" key={path}>{title}</Link>
                )
            })
        }
            <Link to="/pages/new" className="btn btn-flat aresBlue white-text navAddBtn">
                <FontAwesomeIcon icon={faPlus} className="iconMarginRight" />
                Ny side
            </Link>
        </>
     );
}
 
export default AdminNav;