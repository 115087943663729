import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
    return ( 
        <div>
            <h2>Dashbord</h2>
            <Link to="/newsletters" className="waves-effect waves-teal btn aresBlue">
                Se alle nyhetsbrev
                <FontAwesomeIcon icon={faCaretRight} className="iconMarginLeft" />
            </Link>
        </div>
     );
}
 
export default Dashboard;