import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import authReducer from './authReducer';
import newsletterReducer from './newsletterReducer';
import pageReducer from './pageReducer';
import pagesReducer from './pagesReducer';

export default combineReducers({
    auth: authReducer,
    form: reduxForm,
    newsletters: newsletterReducer,
    pages: pagesReducer,
    page: pageReducer,
});