import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import NewsletterList from './NewsletterList';

const Newsletters = () => {
    return ( 
        <div>
            <h2>Alle nyhetsbrev</h2>
            <Link to="/newsletters/new" className="waves-effect waves-teal btn aresBlue">
                <FontAwesomeIcon icon={faPlus} className="iconMarginRight" />
                Opprett nyhetsbrev
            </Link>
            <NewsletterList />
        </div>
     );
}
 
export default Newsletters;