import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import FormField from '../common/FormField';
import validateEmails from '../../utils/validateEmails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import formFields from './fields';

const NewsletterForm = (props) => {
        return(
            <form onSubmit={props.handleSubmit(() => props.toggleShowReview(true))}>
                {
                    formFields.map(({ label, name }) => {
                        return <Field key={name} label={label} type="text" name={name} component={FormField} />
                    })
                }
                <Link to="/dashboard" className="btn red">
                    Avbryt
                    <FontAwesomeIcon icon={faTrash} className="iconMarginLeft" />
                </Link>
                <button
                    type="submit"
                    className="btn aresBlue right"
                >
                    Neste
                    <FontAwesomeIcon icon={faCaretRight} className="iconMarginLeft" />
                </button>
            </form>
        );
}

function validate(values) {
    const errors = {};

    errors.recipients = validateEmails(values.recipients || "");

    formFields.forEach(({ name, errMsg }) => {
        if(!values[name]) {
            errors[name] = errMsg;
        }
    });

    return errors;
}

export default reduxForm({
    validate,
    form: "newsletterForm",
    destroyOnUnmount: false
})(NewsletterForm);