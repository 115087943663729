import { FETCH_PAGE } from "../actions/types";

const pageReducer = (state = false, action) => {
    switch(action.type) {
        case FETCH_PAGE:
            return action.payload;
        default:
            return state;
    }
};

export default pageReducer;