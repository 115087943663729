import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import FormField from '../common/FormField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import formFields from './fields';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const renderEditor = ({input}) => {
        
    console.log(input);

    return (
        <div className="marginTop">
            <CKEditor
                data={input.value}
                editor={ ClassicEditor }
                onChange={(event, editor) => {
                    return editor.getData();
                    }
                }
            />
        </div>
    )
}

const PageNew = ({ handleSubmit, formValues, submitPage, history, fetchPages }) => {

    return(
        <>
            <h2>{formValues && formValues.title ? formValues.title : "Ny side"}</h2>
            <form onSubmit={handleSubmit(() => { submitPage(formValues, history); fetchPages() })}>
                {
                    formFields.map(({ label, name }) => {
                        return <Field key={name} label={label} type="text" name={name} component={FormField} /> 
                    })
                }
                <Field
                    name="body"
                    component={(e) => renderEditor(e)}
                />
                {/* <div className="marginTop">
                    <TextEditor />
                </div> */}
                <Link to="/dashboard" className="btn red">
                    Avbryt
                    <FontAwesomeIcon icon={faTrash} className="iconMarginLeft" />
                </Link>
                <button
                    type="submit"
                    className="btn aresBlue right"
                >
                    Opprett og Publiser Side
                </button>
            </form>
        </>
    );
}

function validate(values) {
    const errors = {};

    formFields.forEach(({ name, errMsg }) => {
        if(!values[name]) {
            errors[name] = errMsg;
        }
    });

    if(values.path && values.path.charAt(0) !== "/") {
        errors.path = "Første tegnet i sidestien må være /"
    }

    return errors;
}

function mapStateToProps(state) {
    return {
        formValues: state.form.pageForm.values
    };
}

export default reduxForm({
    validate,
    form: "pageForm",
})(connect(mapStateToProps, actions)(PageNew));