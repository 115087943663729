import React, { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import PrivateRoute from './PrivateRoute';

import Header from './Header';
import Nav from './navigation/Nav';
import AdminNav from './navigation/AdminNav';
import Login from './Login';
import Page from './pages/Page';
import PageNew from './pages/PageNew';
import PageEdit from './pages/PageEditor';
import Dashboard from './Dashboard';
import Newsletters from './newsletters/Newsletters';
import NewsletterNew from './newsletters/NewsletterNew';

const App = ({ authed, fetchUser, pages, fetchPages }) => {

    //On component mount
    useEffect(() => {
        fetchUser();
        fetchPages();
    },[fetchUser, fetchPages]);

    return(
        <div>
            <BrowserRouter>
                <Header />
                <div className="appContainer">
                    <div className={`navContainer ${authed && "adminContainer"} hide-on-med-and-down`}>
                        {
                            authed
                                ? <AdminNav pages={pages} />
                                : <Nav pages={pages} />
                        }
                    </div>
                    <div className="pageContainer">
                        {
                            pages ? (
                                pages.map(({ _id, title, path, body, lastUpdated }) => {
                                    return(
                                        <Route exact path={path} key={path} render={(props) => 
                                            <Page {...props} pageID={_id} title={title} body={body} lastUpdated={lastUpdated} authed={authed} />
                                        } />
                                    )
                                })
                            ) : (
                                <></> //Loading icon?
                            )
                        }
                        <Route exact path="/login" render={(props) => <Login {...props} authed={authed} />} />
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                        <PrivateRoute exact path="/newsletters" component={Newsletters} />
                        <PrivateRoute exact path="/newsletters/new" component={NewsletterNew} />
                        <PrivateRoute exact path="/pages/new" component={PageNew} />
                        <PrivateRoute exact path="/pages/edit" component={PageEdit} />
                    </div>
                </div>
            </BrowserRouter>
        </div>
    );
};

function mapStateToProps({ auth, pages }) {
    return { 
        authed: auth,
        pages
    };
}

export default connect(mapStateToProps, actions)(App);