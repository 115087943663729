import React from 'react';
import { Link } from 'react-router-dom';

const Nav = ({ pages }) => {
    return ( 
        <>
        {
            pages.map(({ path, title }) => {
                return(
                    <Link to={path} className="navLink" key={path}>{title}</Link>
                )
            })
        }
        </>
     );
}
 
export default Nav;