import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const TextEditor = ({ content, setBody }) => {
    return (
        <div className="textEditor">
            <CKEditor
                editor={ ClassicEditor }
                bodyClass="reverseMaterializeListStyling"
                data={ content }
                config={{ 
                    removePlugins: ["Indent"],
                }}
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log( content, editor );
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    /* console.log( { event, editor, data } ); */
                    setBody(data);
                } }
                /* onBlur={ ( event, editor ) => {
                    console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                    console.log( 'Focus.', editor );
                } } */
            />
        </div>
    );
  }

  export default TextEditor;