import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../untitled.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons';

import SideNav from './navigation/SideNav';

const Header = ({ auth, pages }) => {

    const [showSidenav, toggleShowSidenav] = useState(false);

    const toggleSidenav = () => toggleShowSidenav(!showSidenav);

    return(
        <>
            <div className="navbar-fixed">
                <nav>
                    <div className="nav-wrapper aresBlue">
                        <a
                            href="#"
                            className="sidenav-trigger hamburgerMenuIcon right"
                            onClick={toggleSidenav}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </a>
                        <Link to={auth ? "/dashboard" : "/"} className="brand-logo left">
                            <img src={Logo} alt="Ares Turnforening" id="logo" />
                        </Link>
                        <ul className="right hide-on-med-and-down">
                            {
                                auth === null ? (
                                    <></>
                                ) : !auth ? (
                                    <li>
                                        <Link to="/login">
                                            <FontAwesomeIcon icon={faUser} className="iconMarginRight" /> Logg inn
                                        </Link>
                                    </li>
                                ) : (
                                    <li>
                                        <a href="/api/logout" className="waves-effect waves-teal red btn-flat white-text">Logg ut</a>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </nav>
            </div>
            <SideNav pages={pages} showSidenav={showSidenav} toggleShowSidenav={toggleShowSidenav} auth={auth} />
        </>
    );
};

function mapStateToProps({ auth, pages }) {
    return { auth, pages };
}

export default connect(mapStateToProps)(Header);