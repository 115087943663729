import React from 'react';

const TitleInputField = ({ title, setTitle }) => {

    return (
        <div
            className="titleInputField"
            value={title}
            onBlur={(e) => setTitle(e.currentTarget.innerHTML)}
            contentEditable
            suppressContentEditableWarning
        >
            {title}
        </div>
    )
}

export default TitleInputField;