import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import ADMIN_PAGES from './pages';

const SideNav = ({ pages, showSidenav, toggleShowSidenav, auth }) => {
    return ( 
        <nav
            className="sidenav-overlay"
            onClick={() => toggleShowSidenav()}
            style={{
                display: showSidenav ? "block" : "none",
                opacity: "1"
            }}
        >
            <ul
                className="sidenav"
                id="slide-out"
                style={{
                    transform: showSidenav ? "translateX(0%)" : "",
                    transitionProperty: "transform",
                    transitionDuration: ".3s"
                }}
            >
                {
                    auth && (
                        <>
                        {
                            ADMIN_PAGES.map(({ path, title }) => {
                                return(
                                    <li
                                        key={path}
                                        onClick={() => toggleShowSidenav()}
                                    >
                                        <Link to={path} className="mobileNavLink adminMobileNavLink">{title}</Link>
                                    </li>
                                )
                            })
                        }
                        <hr />
                        </>
                    )
                }
                {
                    pages.map(({ path, title }) => {
                        return(
                            <li
                                key={path}
                                onClick={() => toggleShowSidenav()}
                            >
                                <Link to={path} className="mobileNavLink">{title}</Link>
                            </li>
                        )
                    })
                }
                {
                    auth === null ? (
                        <></>
                    ) : !auth ? (
                        <li className="mobileListItem">
                            <Link to="/login" className="btn-flat aresBlue white-text">
                                <FontAwesomeIcon icon={faUser} className="iconMarginRight" /> Logg inn
                            </Link>
                        </li>
                    ) : (
                        <>
                            <li className="mobileListItem">
                                <Link to="/pages/new" className="btn btn-flat aresBlue white-text navAddBtn">
                                    <FontAwesomeIcon icon={faPlus} className="iconMarginRight" />
                                    Ny side
                                </Link>
                            </li>
                            <li className="mobileListItem" style={{ marginTop: "4rem" }}>
                                <a href="/api/logout" className="waves-effect waves-teal red btn-flat white-text">Logg ut</a>
                            </li>
                        </>
                    )
                }
            </ul>
        </nav>
    );
}
 
export default SideNav;