import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Redirect } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import FormField from './common/FormField';

const Login = ({ authed, history, formValues, login, handleSubmit }) => {
    return (
        <>
        {
            authed
                ? <Redirect to="/dashboard" />
                : (
                    <div className="centerFlexContainer">
                        <div className="loginFormContainer">
                            <h2>Logg inn</h2>
                            <form id="loginForm" onSubmit={handleSubmit(() => login(formValues, history))}>
                                <Field
                                    label="E-post"
                                    type="email"
                                    name="email"
                                    component={FormField}
                                />
                                <Field
                                    label="Passord"
                                    type="password"
                                    name="password"
                                    component={FormField}
                                />
                                <button
                                    type="submit"
                                    className="btn aresBlue loginBtn"
                                >
                                    Logg inn
                                </button>
                            </form>
                            <a href="/auth/google">
                                <div className="google-btn">
                                    <div className="google-icon-wrapper">
                                        <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="Google logo" />
                                    </div>
                                    <p className="btn-text"><b>Logg inn med Google</b></p>
                                </div>
                            </a>
                        </div>
                    </div>
                )
        }
        </>
    );
}

function validate(values) {
    const errors = {};

    if(!values.email) {
        errors.email = "Du må oppgi en e-post";
    }

    if(!values.password) {
        errors.password = "Du må oppgi et passord";
    }

    return errors;
}

function mapStateToProps(state) {
    return {
        formValues: state.form.loginForm.values
    };
}

export default reduxForm({
    validate,
    form: "loginForm",
})(connect(mapStateToProps, actions)(Login));