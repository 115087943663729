import React from 'react';

const FormField = ({ input, label, type, meta: { error, touched } }) => {
    return ( 
        <>
            <label>{label}</label>
            <input {...input} type={type} maxLength="35" className="inputField" />
            {touched && error && <p className="errorText">{error}</p>}
        </>
     );
}
 
export default FormField;