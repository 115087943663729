import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchNewsletters } from '../../actions';
import { PieChart, Pie, Cell } from 'recharts';

const NewsletterList = ({ fetchNewsletters, newsletters }) => {

    useEffect(() => {
        fetchNewsletters();
    },[fetchNewsletters]);

    //              GRAY        GREEN      RED
    const COLORS = ['#b5b5b5', '#1e990e', '#eb4034'];

    return ( 
        <>
        {
            newsletters.reverse().map(newsletter => {
                const data = [
                    { name: 'All', value: newsletter.totalRecipients || 0 },
                    { name: 'Yes', value: newsletter.yes },
                    { name: 'No', value: newsletter.no },
                ];
                return(
                    <div className="card" key={newsletter._id}>
                        <div className="card-content">
                            <div className="flex-container">
                                <div style={{ width: "90%" }}><span className="card-title">{newsletter.title}</span></div>
                                <div style={{ width: "10%", minWidth: "17ch", textAlign: "right" }}><p className="right">Sendt {new Date(newsletter.dateSent).toLocaleDateString()}</p></div>
                            </div>
                            
                            <div className="flex-container">
                                <div className="statsContainer">
                                    <PieChart width={250} height={150}>
                                        <Pie
                                            data={data}
                                            innerRadius={25}
                                            outerRadius={40}
                                            fill="#8884d8"
                                            paddingAngle={1}
                                            dataKey="value"
                                        >
                                            {
                                                data.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))
                                            }
                                        </Pie>
                                    </PieChart>
                                    <span className="bold">Var informasjonen nyttig?</span> <br/>
                                    <span style={{ color: COLORS[1] }}>Ja: {newsletter.yes}</span> <br/>
                                    <span style={{ color: COLORS[2] }}>Nei: {newsletter.no}</span> <br/>
                                    <span style={{ color: COLORS[0] }}>Antall mottakere: {newsletter.totalRecipients || "Ukjent"}</span>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p>{newsletter.body}</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                );
            })
        }
        </>
    );
}

function mapStateToProps({ newsletters }) {
    return { newsletters };
}
 
export default connect(mapStateToProps, { fetchNewsletters })(NewsletterList);